import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ timeout }) => (
	<footer id="footer" style={timeout ? { display: 'none' } : {}}>
		<p className="copyright">
			&copy; Brunner Technical Services LLC. Design:{' '}
			<a href="https://html5up.net">HTML5 UP</a>. Built with:{' '}
			<a href="https://www.gatsbyjs.org/">Gatsby.js</a>
		</p>
	</footer>
);

Footer.propTypes = {
	timeout: PropTypes.bool,
};

export default Footer;
